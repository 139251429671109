@use '@angular/material' as mat;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap');
@include mat.core();
@import './styles/doc-mat-theme.scss';
@tailwind base;
@tailwind components;
@tailwind utilities;


/* -------------------------------------------------------------------------- */
/*                                    Fonts                                   */
/* -------------------------------------------------------------------------- */

@font-face {
	font-family: 'Alef Hebrew';
	src: url('./assets/fonts/Alef-Regular.ttf');
}
@font-face {
	font-family: 'Assistant';
	src: url('./assets/fonts/Assistant/Assistant.ttf');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'Assistant';
	src: url('./assets/fonts/Assistant/Assistant-Bold.ttf');
	font-weight: 700;
	font-style: normal;
}

/* -------------------------------------------------------------------------- */

html,
body {
	height: 100%;
	touch-action: manipulation;
}
body {
	margin: 0;
	font-family: var(--dc-font-family);
}

body {
	font-family: 'Assistant', sans-serif !important;
	direction: rtl;
	text-align: initial;

	.APP-CONTENT {
		width: 100%;
		height: 100%;
		padding: 0.3rem;
		overflow: auto;

		&-WRAPPER {
			@apply h-full w-full border-2 border-solid border-gray-100;
		}
	}

	h1 {
		@apply text-3xl font-bold;
	}

	h2 {
		@apply text-2xl font-bold;
	}

	h3 {
		@apply text-xl font-bold;
	}

	h4 {
		@apply text-lg font-bold;
	}

	h5 {
		@apply text-base font-bold;
	}

	h6 {
		@apply text-sm font-bold;
	}
}
/* -------------------------------------------------------------------------- */
/*                              Spacial Scrollbar                             */
/* -------------------------------------------------------------------------- */
@mixin dc-scrollbar($width: 8px, $height: 5px) {
    &::-webkit-scrollbar {
        width: $width;
        height: $height;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: calc($width / 2);
    }

    &::-webkit-scrollbar-thumb {
        background: rgb(145, 168, 199);
        border-radius: calc($width / 2);
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #6b8fd1;
    }
}
