$color-blue-gradient: linear-gradient(to bottom, rgba(30, 60, 114, 0.118) 0%, rgba(11, 86, 134, 0.043) 100%);
$color-b-100: rgba(223, 246, 255, 1);
$color-b-300: rgba(13, 162, 224, 1);
$color-b-400: rgba(12, 83, 133, 1);
$color-b-500: rgba(33, 55, 105, 1);
$color-b-50: rgba(230, 240, 244, 1);
$color-bg-100: rgba(241, 243, 245, 1);

/* pop up  */
$color-b-50: rgba(33, 55, 105, 0.600);
$color-blue-background: rgba(8, 18, 40, 0.570);
$color-btn-bg-blue: rgba(236, 249, 255, 1);
$color-custom-primary: rgba(33, 55, 105, 1);
$color-custom-secondary: rgba(66, 66, 66, 1);
$color-custom-accent: rgba(130, 177, 255, 1);
$color-custom-error: rgba(255, 82, 82, 1);
$color-custom-info: rgba(33, 150, 243, 1);
$color-custom-success: rgba(76, 175, 80, 1);
$color-custom-warning: rgba(251, 140, 0, 1);
$color-shades-black: rgba(0, 0, 0, 1);
$color-shades-white: rgba(255, 255, 255, 1);
$color-black-0-87: rgba(0, 0, 0, 0.870);
$color-black-0-6: rgba(0, 0, 0, 0.600);
$color-black-0-58: rgba(0, 0, 0, 0.580);
$color-black-0-38: rgba(0, 0, 0, 0.380);
$color-black-0-18: rgba(0, 0, 0, 0.180);
$color-black-0-12: rgba(0, 0, 0, 0.120);
$color-black-0-54: rgba(0, 0, 0, 0.540);
$color-black-0-26: rgba(0, 0, 0, 0.260);
$color-black-0-42: rgba(0, 0, 0, 0.420);
$color-black-0-06: rgba(0, 0, 0, 0.060);
$color-black-0-2: rgba(0, 0, 0, 0.200);
$color-grey-grey: rgba(158, 158, 158, 1);
$color-grey-lighten-5: rgba(250, 250, 250, 1);
$color-grey-lighten-4: rgba(245, 245, 245, 1);
$color-grey-lighten-3: rgba(238, 238, 238, 1);
$color-grey-lighten-2: rgba(224, 224, 224, 1);
$color-grey-lighten-1: rgba(189, 189, 189, 1);
$color-grey-darken-1: rgba(117, 117, 117, 1);
$color-grey-darken-2: rgba(97, 97, 97, 1);
$color-grey-darken-3: rgba(66, 66, 66, 1);
$color-grey-darken-4: rgba(33, 33, 33, 1);
$color-blue-grey-blue-grey: rgba(96, 125, 139, 1);
$color-blue-grey-lighten-5: rgba(236, 239, 241, 1);
$color-blue-grey-lighten-4: rgba(207, 216, 220, 1);
$color-blue-grey-lighten-3: rgba(176, 190, 197, 1);
$color-blue-grey-lighten-2: rgba(144, 164, 174, 1);
$color-blue-grey-lighten-1: rgba(120, 144, 156, 1);
$color-blue-grey-darken-1: rgba(84, 110, 122, 1);
$color-blue-grey-darken-2: rgba(69, 90, 100, 1);
$color-blue-grey-darken-3: rgba(55, 71, 79, 1);
$color-blue-grey-darken-4: rgba(38, 50, 56, 1);
$color-brown-brown: rgba(121, 85, 72, 1);
$color-brown-lighten-5: rgba(239, 235, 233, 1);
$color-brown-lighten-4: rgba(215, 204, 200, 1);
$color-brown-lighten-3: rgba(188, 170, 164, 1);
$color-brown-lighten-2: rgba(161, 136, 127, 1);
$color-brown-lighten-1: rgba(141, 110, 99, 1);
$color-brown-darken-1: rgba(109, 76, 65, 1);
$color-brown-darken-2: rgba(93, 64, 55, 1);
$color-brown-darken-3: rgba(78, 52, 46, 1);
$color-brown-darken-4: rgba(62, 39, 35, 1);
$color-orange-orange: rgba(255, 152, 0, 1);
$color-orange-lighten-5: rgba(255, 243, 224, 1);
$color-orange-lighten-4: rgba(255, 224, 178, 1);
$color-orange-lighten-3: rgba(255, 204, 128, 1);
$color-orange-lighten-2: rgba(255, 183, 77, 1);
$color-orange-lighten-1: rgba(255, 167, 38, 1);
$color-orange-darken-1: rgba(251, 140, 0, 1);
$color-orange-darken-2: rgba(245, 124, 0, 1);
$color-orange-darken-3: rgba(239, 108, 0, 1);
$color-orange-darken-4: rgba(230, 81, 0, 1);
$color-orange-accent-1: rgba(255, 209, 128, 1);
$color-orange-accent-2: rgba(255, 171, 64, 1);
$color-orange-accent-3: rgba(255, 145, 0, 1);
$color-orange-accent-4: rgba(255, 109, 0, 1);
$color-deep-orange-deep-orange: rgba(255, 87, 34, 1);
$color-deep-orange-lighten-5: rgba(251, 233, 231, 1);
$color-deep-orange-lighten-4: rgba(255, 204, 188, 1);
$color-deep-orange-lighten-3: rgba(255, 171, 145, 1);
$color-deep-orange-lighten-2: rgba(255, 138, 101, 1);
$color-deep-orange-lighten-1: rgba(255, 112, 67, 1);
$color-deep-orange-darken-1: rgba(244, 81, 30, 1);
$color-deep-orange-darken-2: rgba(230, 74, 25, 1);
$color-deep-orange-darken-3: rgba(216, 67, 21, 1);
$color-deep-orange-darken-4: rgba(191, 54, 12, 1);
$color-deep-orange-accent-1: rgba(255, 158, 128, 1);
$color-deep-orange-accent-2: rgba(255, 110, 64, 1);
$color-deep-orange-accent-3: rgba(255, 61, 0, 1);
$color-deep-orange-accent-4: rgba(221, 44, 0, 1);
$color-amber-amber: rgba(255, 193, 7, 1);
$color-amber-lighten-5: rgba(255, 248, 225, 1);
$color-amber-lighten-4: rgba(255, 236, 179, 1);
$color-amber-lighten-3: rgba(255, 224, 130, 1);
$color-amber-lighten-2: rgba(255, 213, 79, 1);
$color-amber-lighten-1: rgba(255, 202, 40, 1);
$color-amber-darken-1: rgba(255, 179, 0, 1);
$color-amber-darken-2: rgba(255, 160, 0, 1);
$color-amber-darken-3: rgba(255, 143, 0, 1);
$color-amber-darken-4: rgba(255, 111, 0, 1);
$color-amber-accent-1: rgba(255, 229, 127, 1);
$color-amber-accent-2: rgba(255, 215, 64, 1);
$color-amber-accent-3: rgba(255, 196, 0, 1);
$color-amber-accent-4: rgba(255, 171, 0, 1);
$color-yellow-yellow: rgba(255, 235, 59, 1);
$color-yellow-lighten-5: rgba(255, 253, 231, 1);
$color-yellow-lighten-4: rgba(255, 249, 196, 1);
$color-yellow-lighten-3: rgba(255, 245, 157, 1);
$color-yellow-lighten-2: rgba(255, 241, 118, 1);
$color-yellow-lighten-1: rgba(255, 238, 88, 1);
$color-yellow-darken-1: rgba(253, 216, 53, 1);
$color-yellow-darken-2: rgba(251, 192, 45, 1);
$color-yellow-darken-3: rgba(249, 168, 37, 1);
$color-yellow-darken-4: rgba(245, 127, 23, 1);
$color-yellow-accent-1: rgba(255, 255, 141, 1);
$color-yellow-accent-2: rgba(255, 255, 0, 1);
$color-yellow-accent-3: rgba(255, 234, 0, 1);
$color-yellow-accent-4: rgba(255, 214, 0, 1);
$color-lime-lime: rgba(205, 220, 57, 1);
$color-lime-lighten-5: rgba(249, 251, 231, 1);
$color-lime-lighten-4: rgba(240, 244, 195, 1);
$color-lime-lighten-3: rgba(230, 238, 156, 1);
$color-lime-lighten-2: rgba(220, 231, 117, 1);
$color-lime-lighten-1: rgba(212, 225, 87, 1);
$color-lime-darken-1: rgba(192, 202, 51, 1);
$color-lime-darken-2: rgba(175, 180, 43, 1);
$color-lime-darken-3: rgba(158, 157, 36, 1);
$color-lime-darken-4: rgba(130, 119, 23, 1);
$color-lime-accent-1: rgba(244, 255, 129, 1);
$color-lime-accent-2: rgba(238, 255, 65, 1);
$color-lime-accent-3: rgba(198, 255, 0, 1);
$color-lime-accent-4: rgba(174, 234, 0, 1);
$color-green-green: rgba(76, 175, 80, 1);
$color-green-lighten-5: rgba(232, 245, 233, 1);
$color-green-lighten-4: rgba(200, 230, 201, 1);
$color-green-lighten-3: rgba(165, 214, 167, 1);
$color-green-lighten-2: rgba(129, 199, 132, 1);
$color-green-lighten-1: rgba(102, 187, 106, 1);
$color-green-darken-1: rgba(67, 160, 71, 1);
$color-green-darken-2: rgba(56, 142, 60, 1);
$color-green-darken-3: rgba(46, 125, 50, 1);
$color-green-darken-4: rgba(27, 94, 32, 1);
$color-green-accent-1: rgba(185, 246, 202, 1);
$color-green-accent-2: rgba(105, 240, 174, 1);
$color-green-accent-3: rgba(0, 230, 118, 1);
$color-green-accent-4: rgba(0, 200, 83, 1);
$color-light-green-light-green: rgba(139, 195, 74, 1);
$color-light-green-lighten-5: rgba(241, 248, 233, 1);
$color-light-green-lighten-4: rgba(220, 237, 200, 1);
$color-light-green-lighten-3: rgba(197, 225, 165, 1);
$color-light-green-lighten-2: rgba(174, 213, 129, 1);
$color-light-green-lighten-1: rgba(156, 204, 101, 1);
$color-light-green-darken-1: rgba(124, 179, 66, 1);
$color-light-green-darken-2: rgba(104, 159, 56, 1);
$color-light-green-darken-3: rgba(85, 139, 47, 1);
$color-light-green-darken-4: rgba(51, 105, 30, 1);
$color-light-green-accent-1: rgba(204, 255, 144, 1);
$color-light-green-accent-2: rgba(178, 255, 89, 1);
$color-light-green-accent-3: rgba(118, 255, 3, 1);
$color-light-green-accent-4: rgba(100, 221, 23, 1);
$color-teal-teal: rgba(0, 150, 136, 1);
$color-teal-lighten-5: rgba(224, 242, 241, 1);
$color-teal-lighten-4: rgba(178, 223, 219, 1);
$color-teal-lighten-3: rgba(128, 203, 196, 1);
$color-teal-lighten-2: rgba(77, 182, 172, 1);
$color-teal-lighten-1: rgba(38, 166, 154, 1);
$color-teal-darken-1: rgba(0, 137, 123, 1);
$color-teal-darken-2: rgba(0, 121, 107, 1);
$color-teal-darken-3: rgba(0, 105, 92, 1);
$color-teal-darken-4: rgba(0, 77, 64, 1);
$color-teal-accent-1: rgba(167, 255, 235, 1);
$color-teal-accent-2: rgba(100, 255, 218, 1);
$color-teal-accent-3: rgba(29, 233, 182, 1);
$color-teal-accent-4: rgba(0, 191, 165, 1);
$color-cyan-cyan: rgba(0, 188, 212, 1);
$color-cyan-lighten-5: rgba(224, 247, 250, 1);
$color-cyan-lighten-4: rgba(178, 235, 242, 1);
$color-cyan-lighten-3: rgba(128, 222, 234, 1);
$color-cyan-lighten-2: rgba(77, 208, 225, 1);
$color-cyan-lighten-1: rgba(38, 198, 218, 1);
$color-cyan-darken-1: rgba(0, 172, 193, 1);
$color-cyan-darken-2: rgba(0, 151, 167, 1);
$color-cyan-darken-3: rgba(0, 131, 143, 1);
$color-cyan-darken-4: rgba(0, 96, 100, 1);
$color-cyan-accent-1: rgba(132, 255, 255, 1);
$color-cyan-accent-2: rgba(24, 255, 255, 1);
$color-cyan-accent-3: rgba(0, 229, 255, 1);
$color-cyan-accent-4: rgba(0, 184, 212, 1);
$color-blue-blue: rgba(33, 150, 243, 1);
$color-blue-lighten-5: rgba(227, 242, 253, 1);
$color-blue-lighten-4: rgba(187, 222, 251, 1);
$color-blue-lighten-3: rgba(144, 202, 249, 1);
$color-blue-lighten-2: rgba(100, 181, 246, 1);
$color-blue-lighten-1: rgba(66, 165, 245, 1);
$color-blue-darken-1: rgba(30, 136, 229, 1);
$color-blue-darken-2: rgba(25, 118, 210, 1);
$color-blue-darken-3: rgba(21, 101, 192, 1);
$color-blue-darken-4: rgba(13, 71, 161, 1);
$color-blue-accent-1: rgba(130, 177, 255, 1);
$color-blue-accent-2: rgba(68, 138, 255, 1);
$color-blue-accent-3: rgba(41, 121, 255, 1);
$color-blue-accent-4: rgba(41, 98, 255, 1);
$color-light-blue-light-blue: rgba(3, 169, 244, 1);
$color-light-blue-lighten-5: rgba(225, 245, 254, 1);
$color-light-blue-lighten-4: rgba(179, 229, 252, 1);
$color-light-blue-lighten-3: rgba(129, 212, 250, 1);
$color-light-blue-lighten-2: rgba(79, 195, 247, 1);
$color-light-blue-lighten-1: rgba(41, 182, 246, 1);
$color-light-blue-darken-1: rgba(3, 155, 229, 1);
$color-light-blue-darken-2: rgba(2, 136, 209, 1);
$color-light-blue-darken-3: rgba(2, 119, 189, 1);
$color-light-blue-darken-4: rgba(1, 87, 155, 1);
$color-light-blue-accent-1: rgba(128, 216, 255, 1);
$color-light-blue-accent-2: rgba(64, 196, 255, 1);
$color-light-blue-accent-3: rgba(0, 176, 255, 1);
$color-light-blue-accent-4: rgba(0, 145, 234, 1);
$color-indigo-indigo: rgba(63, 81, 181, 1);
$color-indigo-lighten-5: rgba(232, 234, 246, 1);
$color-indigo-lighten-4: rgba(197, 202, 233, 1);
$color-indigo-lighten-3: rgba(159, 168, 218, 1);
$color-indigo-lighten-2: rgba(121, 134, 203, 1);
$color-indigo-lighten-1: rgba(92, 107, 192, 1);
$color-indigo-darken-1: rgba(57, 73, 171, 1);
$color-indigo-darken-2: rgba(48, 63, 159, 1);
$color-indigo-darken-3: rgba(40, 53, 147, 1);
$color-indigo-darken-4: rgba(26, 35, 126, 1);
$color-indigo-accent-1: rgba(140, 158, 255, 1);
$color-indigo-accent-2: rgba(83, 109, 254, 1);
$color-indigo-accent-3: rgba(61, 90, 254, 1);
$color-indigo-accent-4: rgba(48, 79, 254, 1);
$color-purple-purple: rgba(156, 39, 176, 1);
$color-purple-lighten-5: rgba(243, 229, 245, 1);
$color-purple-lighten-4: rgba(225, 190, 231, 1);
$color-purple-lighten-3: rgba(206, 147, 216, 1);
$color-purple-lighten-2: rgba(186, 104, 200, 1);
$color-purple-lighten-1: rgba(171, 71, 188, 1);
$color-purple-darken-1: rgba(142, 36, 170, 1);
$color-purple-darken-2: rgba(123, 31, 162, 1);
$color-purple-darken-3: rgba(106, 27, 154, 1);
$color-purple-darken-4: rgba(74, 20, 140, 1);
$color-purple-accent-1: rgba(234, 128, 252, 1);
$color-purple-accent-2: rgba(224, 64, 251, 1);
$color-purple-accent-3: rgba(213, 0, 249, 1);
$color-purple-accent-4: rgba(170, 0, 255, 1);
$color-deep-purple-deep-purple: rgba(103, 58, 183, 1);
$color-deep-purple-lighten-5: rgba(237, 231, 246, 1);
$color-deep-purple-lighten-4: rgba(209, 196, 233, 1);
$color-deep-purple-lighten-3: rgba(179, 157, 219, 1);
$color-deep-purple-lighten-2: rgba(149, 117, 205, 1);
$color-deep-purple-lighten-1: rgba(126, 87, 194, 1);
$color-deep-purple-darken-1: rgba(94, 53, 177, 1);
$color-deep-purple-darken-2: rgba(81, 45, 168, 1);
$color-deep-purple-darken-3: rgba(69, 39, 160, 1);
$color-deep-purple-darken-4: rgba(49, 27, 146, 1);
$color-deep-purple-accent-1: rgba(179, 136, 255, 1);
$color-deep-purple-accent-2: rgba(124, 77, 255, 1);
$color-deep-purple-accent-3: rgba(101, 31, 255, 1);
$color-deep-purple-accent-4: rgba(98, 0, 234, 1);
$color-pink-pink: rgba(233, 30, 99, 1);
$color-pink-lighten-5: rgba(252, 228, 236, 1);
$color-pink-lighten-4: rgba(248, 187, 208, 1);
$color-pink-lighten-3: rgba(244, 143, 177, 1);
$color-pink-lighten-2: rgba(240, 98, 146, 1);
$color-pink-lighten-1: rgba(236, 64, 122, 1);
$color-pink-darken-1: rgba(216, 27, 96, 1);
$color-pink-darken-2: rgba(194, 24, 91, 1);
$color-pink-darken-3: rgba(173, 20, 87, 1);
$color-pink-darken-4: rgba(136, 14, 79, 1);
$color-pink-accent-1: rgba(255, 128, 171, 1);
$color-pink-accent-2: rgba(255, 64, 129, 1);
$color-pink-accent-3: rgba(245, 0, 87, 1);
$color-pink-accent-4: rgba(197, 17, 98, 1);
$color-red-red: rgba(244, 67, 54, 1);
$color-red-lighten-5: rgba(255, 235, 238, 1);
$color-red-lighten-4: rgba(255, 205, 210, 1);
$color-red-lighten-3: rgba(239, 154, 154, 1);
$color-red-lighten-2: rgba(229, 115, 115, 1);
$color-red-lighten-1: rgba(239, 83, 80, 1);
$color-red-darken-1: rgba(229, 57, 53, 1);
$color-red-darken-2: rgba(211, 47, 47, 1);
$color-red-darken-3: rgba(198, 40, 40, 1);
$color-red-darken-4: rgba(183, 28, 28, 1);
$color-red-accent-1: rgba(255, 138, 128, 1);
$color-red-accent-2: rgba(255, 82, 82, 1);
$color-red-accent-3: rgba(255, 23, 68, 1);
$color-red-accent-4: rgba(213, 0, 0, 1);

@mixin h1-semi-bold {
  font-size: 2.5rem;
  font-family: "Assistant";
  font-weight: 600;
  font-style: normal;
  letter-spacing: 1.5px;
  text-decoration: none;
  text-transform: none;
}

@mixin h2-semi-bold {
  font-size: 2.188rem;
  font-family: "Assistant";
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  text-transform: none;
}

@mixin h3-semi-bold {
  font-size: 1.875rem;
  font-family: "Assistant";
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  text-transform: none;
}

@mixin h4-semi-bold {
  font-size: 1.562rem;
  font-family: "Assistant";
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  text-transform: none;
}

@mixin h5-semi-bold {
  font-size: 1.25rem;
  font-family: "Assistant";
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  text-transform: none;
}

@mixin h6-semi-bold {
  font-size: 1.125rem;
  font-family: "Assistant";
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  text-transform: none;
}

/* -------------------------------------------------------------------------- */

@mixin text-style-h-4-bold {
  font-size: 1.562rem;
  font-family: "Assistant";
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
  text-transform: none;
}

@mixin text-style-body-1-bold {
  font-size: 1.062rem;
  font-family: "Assistant";
  font-weight: 700;
  font-style: normal;
  line-height: 24px;
  text-decoration: none;
  text-transform: none;
}

@mixin text-style-v-text-h-1 {
  font-size: 2.5rem;
  font-family: "Assistant";
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1.5px;
  text-decoration: none;
  text-transform: none;
}

@mixin text-style-v-text-h-3 {
  font-size: 1.875rem;
  font-family: "Assistant";
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  text-transform: none;
}

@mixin text-style-v-text-h-4 {
  font-size: 1.562rem;
  font-family: "Assistant";
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  text-transform: none;
}

@mixin text-style-v-text-h-5 {
  font-size: 1.25rem;
  font-family: "Assistant";
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  text-transform: none;
}

@mixin text-style-v-text-h-6 {
  font-size: 1.125rem;
  font-family: "Assistant";
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  text-transform: none;
}

@mixin text-style-v-text-subtitle-1 {
  font-size: 0.938rem;
  font-family: "Assistant";
  font-weight: 400;
  font-style: normal;
  line-height: 28px;
  letter-spacing: 0.15px;
  text-decoration: none;
  text-transform: none;
}

@mixin text-style-v-text-subtitle-2 {
  font-size: 0.875rem;
  font-family: "Assistant";
  font-weight: 400;
  font-style: normal;
  line-height: 22px;
  letter-spacing: 0.1px;
  text-decoration: none;
  text-transform: none;
}

@mixin text-style-v-text-body-1 {
  font-size: 1.062rem;
  font-family: "Assistant";
  font-weight: 400;
  font-style: normal;
  line-height: 24px;
  text-decoration: none;
  text-transform: none;
}

@mixin text-style-v-text-body-2 {
  font-size: 0.875rem;
  font-family: "Assistant";
  font-weight: 400;
  font-style: normal;
  line-height: 20px;
  text-decoration: none;
  text-transform: none;
}

@mixin text-style-v-text-button {
  font-size: 0.875rem;
  font-family: "Assistant";
  font-weight: 600;
  font-style: normal;
  line-height: 36px;
  letter-spacing: 1.25px;
  text-decoration: none;
  text-transform: uppercase;
}

@mixin text-style-v-text-h-2 {
  font-size: 2.188rem;
  font-family: "Assistant";
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  text-transform: none;
}

@mixin text-style-v-text-caption {
  font-size: 0.75rem;
  font-family: "Assistant";
  font-weight: 400;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0.4px;
  text-decoration: none;
  text-transform: none;
}

@mixin text-style-v-text-overline {
  font-size: 0.75rem;
  font-family: "Assistant";
  font-weight: 400;
  font-style: normal;
  line-height: 32px;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
}

@mixin text-style-v-icon-x-small {
  font-size: 0.75rem;
  font-family: "Material Icons";
  font-weight: 400;
  font-style: normal;
  line-height: 100%;
  text-decoration: none;
  text-transform: none;
}

@mixin text-style-v-icon-small {
  font-size: 1rem;
  font-family: "Material Icons";
  font-weight: 400;
  font-style: normal;
  line-height: 100%;
  text-decoration: none;
  text-transform: none;
}

@mixin text-style-v-icon-dense {
  font-size: 1.25rem;
  font-family: "Material Icons";
  font-weight: 400;
  font-style: normal;
  line-height: 100%;
  text-decoration: none;
  text-transform: none;
}

@mixin text-style-v-icon-medium {
  font-size: 1.5rem;
  font-family: "Material Icons";
  font-weight: 400;
  font-style: normal;
  line-height: 100%;
  text-decoration: none;
  text-transform: none;
}

@mixin text-style-v-icon-large {
  font-size: 2.25rem;
  font-family: "Material Icons";
  font-weight: 400;
  font-style: normal;
  line-height: 100%;
  text-decoration: none;
  text-transform: none;
}

@mixin text-style-v-icon-x-large {
  font-size: 2.5rem;
  font-family: "Material Icons";
  font-weight: 400;
  font-style: normal;
  line-height: 100%;
  text-decoration: none;
  text-transform: none;
}

@mixin effect-style-default {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.200), 0px 2px 2px rgba(0, 0, 0, 0.140), 0px 1px 5px rgba(0, 0, 0, 0.120);
}

@mixin effect-style-default {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.200), 0px 2px 2px rgba(0, 0, 0, 0.140), 0px 1px 5px rgba(0, 0, 0, 0.120);
}

@mixin effect-style-default {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.200), 0px 2px 2px rgba(0, 0, 0, 0.140), 0px 1px 5px rgba(0, 0, 0, 0.120);
}

@mixin effect-style-active {
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.200), 0px 9px 10px rgba(0, 0, 0, 0.140), 0px 5px 14px rgba(0, 0, 0, 0.120);
}

@mixin effect-style-active {
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.200), 0px 9px 10px rgba(0, 0, 0, 0.140), 0px 5px 14px rgba(0, 0, 0, 0.120);
}

@mixin effect-style-active {
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.200), 0px 9px 10px rgba(0, 0, 0, 0.140), 0px 5px 14px rgba(0, 0, 0, 0.120);
}

@mixin effect-style-elevation-0 {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.200);
}

@mixin effect-style-elevation-1 {
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.200);
}

@mixin effect-style-elevation-2 {
  box-shadow: 0px 3px 1px rgba(0, 0, 0, 0.200);
}

@mixin effect-style-elevation-3 {
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.200);
}

@mixin effect-style-elevation-4 {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.200);
}

@mixin effect-style-elevation-5 {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.200);
}

@mixin effect-style-elevation-6 {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.200);
}

@mixin effect-style-elevation-7 {
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.200);
}

@mixin effect-style-elevation-8 {
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.200);
}

@mixin effect-style-elevation-9 {
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.200);
}

@mixin effect-style-elevation-10 {
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.200);
}

@mixin effect-style-elevation-11 {
  box-shadow: 0px 6px 7px rgba(0, 0, 0, 0.200);
}

@mixin effect-style-elevation-12 {
  box-shadow: 0px 7px 8px rgba(0, 0, 0, 0.200);
}

@mixin effect-style-elevation-13 {
  box-shadow: 0px 7px 8px rgba(0, 0, 0, 0.200);
}

@mixin effect-style-elevation-14 {
  box-shadow: 0px 7px 9px rgba(0, 0, 0, 0.200);
}

@mixin effect-style-elevation-15 {
  box-shadow: 0px 8px 9px rgba(0, 0, 0, 0.200);
}

@mixin effect-style-elevation-16 {
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.200);
}

@mixin effect-style-elevation-17 {
  box-shadow: 0px 8px 11px rgba(0, 0, 0, 0.200);
}

@mixin effect-style-elevation-18 {
  box-shadow: 0px 9px 11px rgba(0, 0, 0, 0.200);
}

@mixin effect-style-elevation-19 {
  box-shadow: 0px 9px 12px rgba(0, 0, 0, 0.200);
}

@mixin effect-style-elevation-20 {
  box-shadow: 0px 10px 13px rgba(0, 0, 0, 0.200);
}

@mixin effect-style-elevation-21 {
  box-shadow: 0px 10px 13px rgba(0, 0, 0, 0.200);
}

@mixin effect-style-elevation-22 {
  box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.200);
}

@mixin effect-style-elevation-23 {
  box-shadow: 0px 11px 14px rgba(0, 0, 0, 0.200);
}

@mixin effect-style-elevation-24 {
  box-shadow: 0px 11px 15px rgba(0, 0, 0, 0.200);
}
