@use '@angular/material' as mat;
@use './doc-theme-variables.scss' as dcVars;
@import './doc-theme.scss';
@include mat.core();

$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

:root {
	--mdc-fab-icon-color: white;
}

$light-primary-text: white;

$dc-primary: mat.define-palette(
	(
		50: #e3f2fd,
		100: #bbdefb,
		200: #90caf9,
		300: #64b5f6,
		400: #42a5f5,
		500: #213769,
		600: #1e88e5,
		700: #1976d2,
		800: #1565c0,
		900: #0d47a1,
		A100: #82b1ff,
		A200: #448aff,
		A400: #2979ff,
		A700: #2962ff,
		contrast: (
			50: $dark-primary-text,
			100: $dark-primary-text,
			200: $dark-primary-text,
			300: $dark-primary-text,
			400: $dark-primary-text,
			500: $light-primary-text,
			600: $light-primary-text,
			700: $light-primary-text,
			800: $light-primary-text,
			900: $light-primary-text,
			A100: $dark-primary-text,
			A200: $light-primary-text,
			A400: $light-primary-text,
			A700: $light-primary-text,
		),
	)
);

$dc-accent: mat.define-palette(
	(
		50: #fafafa,
		100: #f5f5f5,
		200: #eeeeee,
		300: #e0e0e0,
		400: #bdbdbd,
		500: #424242,
		600: #757575,
		700: #616161,
		800: #424242,
		900: #212121,
		A100: #ffffff,
		A200: #eeeeee,
		A400: #bdbdbd,
		A700: #616161,
		contrast: (
			50: $dark-primary-text,
			100: $dark-primary-text,
			200: $dark-primary-text,
			300: $dark-primary-text,
			400: $dark-primary-text,
			500: $dark-primary-text,
			600: $light-primary-text,
			700: $light-primary-text,
			800: $light-primary-text,
			900: $light-primary-text,
			A100: $dark-primary-text,
			A200: $dark-primary-text,
			A400: $dark-primary-text,
			A700: $light-primary-text,
		),
	)
);

$my-primary: mat.define-palette($dc-primary, 500);
$my-accent: mat.define-palette($dc-accent, 500);
$dc-typography: mat.define-typography-config(
	$font-family: 'Poppins, Assistant, Tajawal, sans-serif',
	$headline-1: mat.define-typography-level(96px, 112px, 300),
	$headline-2: mat.define-typography-level(60px, 72px, 300),
	$headline-3: mat.define-typography-level(48px, 56px, 400),
	$headline-4: mat.define-typography-level(34px, 40px, 400),
	$headline-5: mat.define-typography-level(24px, 32px, 400),
	$headline-6: mat.define-typography-level(20px, 28px, 500),
	$subtitle-1: mat.define-typography-level(24px, 32px, 700),
	$subtitle-2: mat.define-typography-level(20px, 28px, 700),
	$body-1: mat.define-typography-level(16px, 24px, 400),
	$body-2: mat.define-typography-level(14px, 20px, 400),
	$button: mat.define-typography-level(14px, 20px, 500),
	$caption: mat.define-typography-level(12px, 16px, 400),
	$overline: mat.define-typography-level(10px, 14px, 400),
);

$my-theme: mat.define-light-theme(
	(
		color: (
			primary: $my-primary,
			accent: $my-accent,
		),
		typography: $dc-typography,
	)
);

@include mat.all-component-themes($my-theme);

/* -------------------------------------------------------------------------- */
/*                                Header Sizes                                */
/* -------------------------------------------------------------------------- */
// Example: <h5 semi>{{ col.name | translate }}</h5>
h1,
.mat-h1 {
	&[semi] {
		@include dcVars.h1-semi-bold;
	}
}

h2,
.mat-h2 {
	&[semi] {
		@include dcVars.h2-semi-bold;
	}
}

h3,
.mat-h3 {
	&[semi] {
		@include dcVars.h3-semi-bold;
	}
}

h4,
.mat-h4 {
	&[semi] {
		@include dcVars.h4-semi-bold;
	}
}

h5,
.mat-h5 {
	&[semi] {
		@include dcVars.h5-semi-bold;
	}
}

h6,
.mat-h6 {
	&[semi] {
		@include dcVars.h6-semi-bold;
	}
}

/* -------------------------------------------------------------------------- */
/*                                   Denses                                   */
/* -------------------------------------------------------------------------- */

.dense-1 {
	@include mat.all-component-densities(-1);
}

.dense-2 {
	@include mat.all-component-densities(-2);
}

.dense-min {
	@include mat.all-component-densities(minimum);
}

/* -------------------------------------------------------------------------- */
/*                              Matrial Overrides                             */
/* -------------------------------------------------------------------------- */
.mdc-tooltip--multiline .mdc-tooltip__surface {
	white-space: break-spaces !important;
}

.disable-mat-subscript {
	.mat-mdc-form-field-subscript-wrapper {
		display: none !important;
	}
}

.mat-calendar-body-selected {
	color: white !important;
}

mat-checkbox.scale08 {
	scale: 0.6 !important;

	.mat-mdc-checkbox-touch-target {
		height: 1rem !important;
		width: 1rem !important;
	}
}

.mat-mdc-slide-toggle.mat-primary {
	label {
		margin: 0 !important;
		font-size: 15px;
	}
}

.mat-mdc-menu-panel {
	.mat-mdc-menu-item {
		min-height: 1.5rem;
		font-size: 15px;
	}

	.mat-mdc-menu-content {
		padding: 2px 0;
	}

	.mdc-list-item__primary-text {
		width: 100%;
	}
}

[dir='rtl'] .mat-mdc-menu-panel.dc-revert-menu-checkboxes {
	& .mat-mdc-menu-item {
		justify-content: end;
		padding: 0;
		padding-left: 1rem;
	}

	& .mat-mdc-menu-item.mat-mdc-menu-item-submenu-trigger {
		padding-right: 32px;
		padding-left: 16px;
	}

	& .mat-mdc-menu-submenu-icon {
		right: 16px;
		left: auto;
		transform: translateY(-50%) scaleX(+1);
	}
}

[dir='ltr'] .mat-mdc-menu-panel.dc-revert-menu-checkboxes {
	& .mat-mdc-menu-item {
		justify-content: end;
		padding: 0;
		padding-right: 1rem;
	}

	& .mat-mdc-menu-item.mat-mdc-menu-item-submenu-trigger {
		padding-right: 16px;
		padding-left: 32px;
	}

	& .mat-mdc-menu-submenu-icon {
		right: auto;
		left: 16px;
		transform: translateY(-50%) scaleX(-1);
	}
}

.mat-mdc-menu-panel {
	&.min-w-0 {
		min-width: 0 !important;
	}

	&.max-w-none {
		max-width: none !important;
	}
}

/* -------------------------------------------------------------------------- */
/*                               Toggle Buttons                               */
/* -------------------------------------------------------------------------- */
.mat-button-toggle-label-content {
	height: 100% !important;
	padding: 0 1rem !important;
}

.mat-button-toggle-checked.no-checked-bg {
	background-color: transparent !important;
}

/* -------------------------------------------------------------------------- */
/*                                  Snackbar                                  */
/* -------------------------------------------------------------------------- */
.dc-snackbar- {
	&small {
		* {
			font-size: small !important;
		}
	}
	&medium {
		* {
			font-size: medium !important;
		}
	}
	&large {
		* {
			font-size: large !important;
		}
	}
	&xlarge {
		* {
			font-size: x-large !important;
		}
	}
	/* -------------------------------------------------------------------------- */
	&normal {
		* {
			font-weight: bold !important;
		}
	}
	&bold {
		* {
			font-weight: normal !important;
		}
	}
	&bolder {
		* {
			font-weight: bolder !important;
		}
	}
	&lighter {
		* {
			font-weight: lighter !important;
		}
	}
	/* -------------------------------------------------------------------------- */
	&text-start {
		* {
			text-align: start !important;
		}
	}
	&text-center {
		* {
			text-align: center !important;
		}
	}
	&text-end {
		* {
			text-align: end !important;
		}
	}
	&text-justify {
		* {
			text-align: justify !important;
		}
	}
	/* -------------------------------------------------------------------------- */
}

/* -------------------------------------------------------------------------- */
/*                                 Mat Select                                 */
/* -------------------------------------------------------------------------- */
.mdc-menu-surface {
	&.mat-mdc-select-panel {
		&.py-0 {
			padding-top: 0;
			padding-bottom: 0;
		}
		&.max-h-60vh {
			max-height: 60vh;
		}
	}

	&.aside {
		[dir='rtl'] & {
			margin: -24px 15px 0 0;
		}

		[dir='ltr'] & {
			margin: -24px -24px 0 0;
		}
	}
}

/* -------------------------------------------------------------------------- */
/*                                Mat Side Nav                                */
/* -------------------------------------------------------------------------- */
.dc-sidenav {
	@apply rounded-md;
	border: 2px solid #e0e0e0 !important;

	.mat-drawer-inner-container {
		@apply flex flex-col justify-between;
	}
}

/* -------------------------------------------------------------------------- */
/*                                  Mat List                                  */
/* -------------------------------------------------------------------------- */
.mat-mdc-list-item-unscoped-content {
	display: flex;
	align-items: center;
	gap: 0.25rem;
}

/* -------------------------------------------------------------------------- */
/*                                 Mat Tooltip                                */
/* -------------------------------------------------------------------------- */

.text-lg {
	.mdc-tooltip__surface {
		font-size: 1.125rem /* 18px */ !important;
		line-height: 1.75rem /* 28px */ !important;
	}
}

.font-bold {
	.mdc-tooltip__surface {
		font-weight: 700 !important; /* bold */
	}
}
/* -------------------------------------------------------------------------- */
/*                              Spacial Scrollbar                             */
/* -------------------------------------------------------------------------- */
@mixin dc-scrollbar($width: 8px, $height: 5px) {
    &::-webkit-scrollbar {
        width: $width;
        height: $height;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: calc($width / 2);
    }

    &::-webkit-scrollbar-thumb {
        background: rgb(145, 168, 199);
        border-radius: calc($width / 2);
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #6b8fd1;
    }
	.dc-scrollbar {
		@include dc-scrollbar();
	}
}