:root {
	--success: rgba(76, 175, 80, 1);
	--success-lighten: rgba(232, 245, 233, 1);

	--warn: rgba(251, 140, 0, 1);
	--warn-lighten: rgba(255, 243, 224, 1);

	--primary: rgba(33, 55, 105, 1);
	--primary-lighten: rgba(173, 191, 215, 1);

	--secondary: rgba(66, 66, 66, 1);
	--secondary-lighten: rgba(189, 189, 189, 1);

	--error: rgba(255, 82, 82, 1);
	--error-lighten: rgba(255, 229, 229, 1)

	--accent: rgba(130, 177, 255, 1);
  --accent-lighten: rgba(214, 235, 255, 1);


  --info: rgba(33, 150, 243, 1);
  --info-lighten: rgba(197, 232, 249, 1);

	$palette: (
		primary: var(--primary),
    primary-lighten: var(--primary-lighten),
		secondary: var(--secondary),
    secondary-lighten: var(--secondary-lighten),
		error: var(--error),
    error-lighten: var(--error-lighten),
		accent: var(--accent),
    accent-lighten: var(--accent-lighten),
		info: var(--info),
    info-lighten: var(--info-lighten),
		success: var(success),
    success-lighten: var(--success-lighten),
		warn: var(--warn),
    warn-lighten: var(--warn-lighten),
	);

	@each $name, $color in $palette {
		.#{$name} {
			color: $color;
		}
	}

	@each $name, $color in $palette {
		.bg-#{$name} {
			background-color: $color;
		}
	}

	@each $name, $color in $palette {
		.border-#{$name} {
			border-color: $color;
		}
	}

	/* ----------------------------- snacbar colors: ---------------------------- */
	$snackbar-color-types: (
		info: var(--info),
		success: var(success),
		warn: var(--warn),
		error: var(--error),
	);

	@each $name, $color in $snackbar-color-types {
		.snackbar-#{$name} {
			.mdc-snackbar__surface {
				background-color: $color;
			}
		}
	}

	/* -------------------------------------------------------------------------- */
	/* -------------------------------------------------------------------------- */
	/*                                  Dc Tables                                 */
	/* -------------------------------------------------------------------------- */
	--table-selected-row: rgb(223, 246, 255);

	.table {
		.row {
			&.selected {
				background-color: var(--table-selected-row);
			}
		}
	}
}
