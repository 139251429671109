:root {
/* ---------------------------------- Fonts --------------------------------- */
  --dc-font-family: 'Assistant', sans-serif;
  /* ------------------------------ Color Palette ----------------------------- */
	--dc-success: rgba(76, 175, 80, 1);
	--dc-success-lighten: rgba(232, 245, 233, 1);

	--dc-warn: rgba(251, 140, 0, 1);
	--dc-warn-lighten: rgba(255, 243, 224, 1);

	--dc-primary: rgba(33, 55, 105, 1);
	--dc-primary-lighten: rgba(173, 191, 215, 1);

	--dc-secondary: rgba(66, 66, 66, 1);
	--dc-secondary-lighten: rgba(189, 189, 189, 1);

	--dc-error: rgba(255, 82, 82, 1);
	--dc-error-lighten: rgba(255, 229, 229, 1)

	--dc-accent: rgba(130, 177, 255, 1);
  --dc-accent-lighten: rgba(214, 235, 255, 1);


  --dc-info: rgba(33, 150, 243, 1);
  --dc-info-lighten: rgba(197, 232, 249, 1);

	$dc-palette: (
		primary: var(--dc-primary),
    primary-lighten: var(--dc-primary-lighten),
		secondary: var(--dc-secondary),
    secondary-lighten: var(--dc-secondary-lighten),
		error: var(--dc-error),
    error-lighten: var(--dc-error-lighten),
		accent: var(--dc-accent),
    accent-lighten: var(--dc-accent-lighten),
		info: var(--dc-info),
    info-lighten: var(--dc-info-lighten),
		success: var(--dc-success),
    success-lighten: var(--dc-success-lighten),
		warn: var(--dc-warn),
    warn-lighten: var(--dc-warn-lighten),
	);

	@each $name, $color in $dc-palette {
		.dc-#{$name} {
			color: $color;
		}
	}

	@each $name, $color in $dc-palette {
		.dc-bg-#{$name} {
			background-color: $color;
		}
	}

	@each $name, $color in $dc-palette {
		.dc-border-#{$name} {
			border-color: $color;
		}
	}
  /* ---------------------------------- Bidi ---------------------------------- */
  $unicode-bidi-options: (
    bidi-override: bidi-override,
    isolate: isolate,
    isolate-override: isolate-override,
    plaintext: plaintext,
    embed: embed,
    normal: normal,
  );

  @each $name, $value in $unicode-bidi-options {
    .bidi-#{$name} {
      unicode-bidi: $value;
    }
  }

	/* ----------------------------- snacbar colors: ---------------------------- */
	$snackbar-color-types: (
		info: var(--dc-info),
		success: var(--dc-success),
		warn: var(--dc-warn),
		error: var(--dc-error),
	);

	@each $name, $color in $snackbar-color-types {
		.dc-snackbar-#{$name} {
			.mdc-snackbar__surface {
				background-color: $color;
			}
		}
	}

	/* -------------------------------------------------------------------------- */
	/* -------------------------------------------------------------------------- */
	/*                                  Dc Tables                                 */
	/* -------------------------------------------------------------------------- */
	--dc-table-selected-row: rgb(223, 246, 255);

	.dc-table {
		.dc-row {
			&.selected {
				background-color: var(--dc-table-selected-row);
			}
		}
	}
}

/* -------------------------------------------------------------------------- */
/*                              Spacial Scrollbar                             */
/* -------------------------------------------------------------------------- */
@mixin dc-scrollbar($width: 8px, $height: 5px) {
    &::-webkit-scrollbar {
        width: $width;
        height: $height;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: calc($width / 2);
    }

    &::-webkit-scrollbar-thumb {
        background: rgb(145, 168, 199);
        border-radius: calc($width / 2);
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #6b8fd1;
    }
}
